import React from "react";
import { Route, Routes } from "react-router-dom";

import Home from "../page/Home";
import Project from "../page/Project";
import Contact from "../page/Contact";
import Education from "../page/Education";
import Work from "../page/Work";
import Footer from "../components/footer/Footer";

import "./routes-handler.scss";

const RoutesHandler = () => {
	return (
		<div className="route-wrapper">
			<Routes>
				<Route path='/' element={<Home />} />
				<Route path='/project' element={<Project />} />
				<Route path='/work' element={<Work />} />
				<Route path='/education' element={<Education />} />
				<Route path='/contact' element={<Contact />} />
      </Routes>
      <Footer />
		</div>
	);
};

export default RoutesHandler;
