import React from 'react';
import TimeLine from '../components/education-time-line/TimeLine';


const Education = () => {
  return (
		<div>
			<div>
				<h2 className='page-title'>Work Experience & Education</h2>
			</div>
			<div>
				<TimeLine />
			</div>
		</div>
	);
}

export default Education;