export const skillsData = {
	title: "Skills",
	list: [
		{
			name: "HTML",
			level: 95,
		},
		{
			name: "CSS",
			level: 95,
		},
		{
			name: "SCSS",
			level: 90
		},
		{
			name: "React",
			level: 85,
		},
		{
			name: "SQL",
			level: 80
		},
		{
			name: "MongoDB",
			level: 90
		},
		{
			name: "Node",
			level: 70,
		},
		{
			name: "Java",
			level: 90,
		},
		{
			name: "C#",
			level: 79,
		},
		{
			name: "C++",
			level: 50,
		},
		{
			name: "Angular",
			level: 60,
		},
		{
			name: "JavaScript",
			level: 80,
		},
		{
			name: "React Native",
			level: 65,
		},
	],
};