import React from "react";
import Button from "../button/Button";
import SocialButton, { OutlineButton } from "../button/Button";
import SkillsList from "../skills-list/SkillsList";
import profile from '../../assets/pictures/personal/profile.JPG';

import { skillsData } from "../../data/skills-data";

import { motion } from "framer-motion"


import "./side-bar-left.scss";

const SideBarLeft = () => {
  const codingSkills = skillsData;

  const cvLink =
		"https://drive.google.com/file/d/1BVsLQWjmi5hrsoRbjI2vJXee-d52eebe/view";

  const downLoadCv = () => {
    window.open(cvLink, "_blank");
  }
	return (
		<motion.div className='side-bar-left'>
			<div className='profile-section'>
				<div className='profile-picture-wrapper'></div>
				<div className='profile-info-wrapper'>
					<h3>Axan Similien</h3>
					<p>Software Developer</p>
					<div className='profile-socials'>
						<SocialButton
							onClick={() =>
								window.open("https://github.com/saxanws", "_blank")
							}
							className='btn-social-icon'
						>
							<i className='bx bxl-github'></i>
						</SocialButton>
						<SocialButton
							onClick={() =>
								window.open(
									"https://www.linkedin.com/in/axansimilien/",
									"_blank"
								)
							}
							className='btn-social-icon'
						>
							<i className='bx bxl-linkedin'></i>
						</SocialButton>
					</div>
				</div>
			</div>
			<hr />
			<div className='overview'>
				<SkillsList skills={codingSkills} />
			</div>
			<div className="skill-scroll">Scroll for more</div>
			<hr />
			<div className='btn-cv'>
				<Button
					onClick={() =>
						window.open(
							"https://drive.google.com/file/d/1BVsLQWjmi5hrsoRbjI2vJXee-d52eebe/view",
							"_blank"
						)
					}
				>
					<span>Resumé</span>
					<i className='bx bx-download bx-sm'></i>
				</Button>
			</div>
		</motion.div>
	);
};

export default SideBarLeft;
