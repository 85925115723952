import React from 'react';
import { motion } from 'framer-motion';

import './skill-progress-bar.scss';

const SkillLevelBar = props => {
  return (
    <div className='level-bar-wrapper'>
		<motion.div
			className='level-bar'
        initial={{ width: 0 }}
        transition={{duration: 0.1, delay: 1 }}
			  animate={{ width: props.level + "%" }}
      ></motion.div>
      </div>
	);
}

export default SkillLevelBar;