import React, { useState, useEffect } from "react";
import ProjectCard from "../components/project-card/ProjectCard";
import {
	DiJava,
	DiPhotoshop,
	DiJavascript,
	DiSass,
	DiReact,
	DiMaterializecss
} from "react-icons/di";

import "animate.css";



import { projects } from "../data/projects-data";

const Project = () => {
	const [projectsList, setProjectsList] = useState([]);
	const [selectedL, setSelectedL] = useState(["active", "", "", "", "", ""])

	const selectLanguages = (language, i) => {
		let theProject = [];
		let newSelect = ["", "", "", "", "", ""];

		setProjectsList([]);

		if (language !== "All") {
			theProject = projects.filter(project => {
				return project.technologies.some(el => {
					if (el.techName === language)
						return project;
				})
			});
		}

		newSelect[i] = "active";


		if (language === "All") {
			theProject = projects;
		}

		setSelectedL(newSelect);
		setProjectsList(theProject);


	}

	useEffect(() => {
		setProjectsList(projects);
	}, [])

	return (
		<div className="animate__animated animate__fadeIn">
			<h2 className='page-title'>Personal Projects</h2>
			<br />
			<div className="project-list-group">
				<ul>
					<li className={`${selectedL[0]}`} onClick={() => selectLanguages("All", 0)}>All</li>
					<li className={`${selectedL[1]}`} onClick={() => selectLanguages("Java", 1)}>Java</li>
					<li className={`${selectedL[2]}`} onClick={() => selectLanguages("ReactJs", 2)}>JavaScript</li>
					<li className={`${selectedL[3]}`} onClick={() => selectLanguages("ReactJs", 3)}>ReactJs</li>
					<li className={`${selectedL[4]}`} onClick={() => selectLanguages("C#", 4)}>C#</li>
				</ul>
			</div>
			<hr />
			<br />
			{
				projectsList?.map((project, i) => {
					return (
						<div key={i}>
							<ProjectCard project={project} key={i} />
							<hr></hr>
							<br /><br />
						</div>
					);
				})
			}
		</div>
	);
};

export default Project;
