import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';

import './slider-custom.scss';
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper";


const SliderCustom = props =>  {
  return (
		<div className='slider-custom-wrapper'>
			<Swiper
				navigation={true}
				pagination={true}
				mousewheel={true}
				slidesPerView={1}
				spaceBetween={0}
				grabCursor={true}
				cssMode={true}
				modules={[Navigation, Pagination, Mousewheel, Keyboard]}
			>
				{props.children.map((item, i) => {
					return <SwiperSlide key={i}>{item}</SwiperSlide>;
				})}
				{/* <SwiperSlide>Slide 1</SwiperSlide>
				<SwiperSlide>Slide 2</SwiperSlide>
				<SwiperSlide>Slide 3</SwiperSlide>
				<SwiperSlide>Slide 4</SwiperSlide> */}
			</Swiper>
		</div>
	);
}

export default SliderCustom