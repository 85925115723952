import React from 'react';
import Header from '../components/header/Header';
import About from '../components/about/About';



const Home = () => {
  return (
    <div >
      <Header />
      <About/>
    </div>
  )
}

export default Home;