import React from "react";
import { PropTypes } from "prop-types";
import "./button.scss";

const Button = (props) => {
	return (
		<button
			className={`btn ${props.className}`}
			onClick={props.onClick ? () => props.onClick() : null}
		>
			{props.children}
		</button>
	);
};

export const OutlineButton = (props) => {
	return (
		<Button
			className={`btn-outline ${props.className}`}
			onClick={props.onClick ? () => props.onClick() : null}
		>
			{props.children}
		</Button>
	);
};

export const SocialButton = (props) => {
	return (
		<Button
			className={props.className}
			onClick={props.onClick ? () => props.onClick() : null}
		>
			{props.children}
		</Button>
	);
};

export const NavButton = (props) => {
	return (
		<>
			<Button
				className={`${props.className} ${props.active}`}
				onClick={props.onClick ? () => props.onClick() : null}
			>
				{props.children}
			</Button>
			<div className="nav-button-title">{props.title}</div>
		</>
	);
};

Button.propTypes = {
	onclick: PropTypes.func,
};

export default Button;
