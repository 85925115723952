import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { SocialButton, NavButton } from '../button/Button';

import './side-bar-right.scss';

const SideBarRight = () => {
	const [active, setActive] = useState(['active', '', '', '', '']);
	
	let navigate = useNavigate();

	const cliked = (index,path) => {
		const newActiveState = ['', '', '',];
		newActiveState[index] = 'active';
		setActive(newActiveState);
		navigate(`/${path}`)
	}
  return (
		<div className='side-bar-right'>
			{/* <div></div> */}
			<div className='nav-buttons'>
				<NavButton
					className={`btn-nav-icon nav-btn`}
					active={active[0]}
				  onClick={() => cliked(0, '')}
				  title="Home"
				>
				  <i className='bx bxs-home-alt-2'></i>
				</NavButton>
				<NavButton
					className='btn-nav-icon'
					active={active[1]}
				  onClick={() => cliked(1, 'project')}
				  title = "Projects"
				>
					<i className='bx bx-code-block'></i>
				</NavButton>
				{/* <NavButton
					className='btn-nav-icon'
					active={active[2]}
					onClick={() => cliked(2,'work')}
				>
					<i className='bx bxs-briefcase'></i>
				</NavButton> */}
				<NavButton
					className='btn-nav-icon'
					active={active[3]}
				  onClick={() => cliked(3, 'education')}
				  title ="Job/School"
				>
				  <i className='bx bxs-graduation'></i>
				</NavButton>
				{/* <NavButton
					className='btn-nav-icon'
					active={active[4]}
					onClick={() => cliked(4, 'contact')}
				>
					<i className='bx bxs-comment-dots'></i>
				</NavButton> */}
			</div>
			{/* <div></div> */}
		</div>
	);
}

export default SideBarRight