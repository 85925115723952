import React from 'react';
import SkillLevelBar from '../skill-bar/SkillLevelBar';
import './skills-list.scss';

const SkillsList = ({skills}) => {
    console.log(skills);
    const { title, list } = skills;
  return (
      <div className='skills-list-wrapper'>
          <h3>{title}</h3>
          <div className='skill-list'>
              {
                  list.map((item, index) => <SkillDisplay name={item.name} level={item.level} key={index}/> )
              }
          </div>
          {/* <div className="skill-scroll">Scroll for more</div> */}
      </div>
  )
}

const SkillDisplay = ({name, level}) => {
    return (
			<div className='skill-item'>
				<div className='skill-item-header'>
                    <span>{ name}</span>
                    <span>{ `${level}%`}</span>
				</div>
				<SkillLevelBar level={level} />
			</div>
		);
}

export default SkillsList;