import React, {useState, useEffect} from "react";

import matcheezCover from "../../assets/project/matcheez-cover.jpeg";

import { DiJava, DiPhotoshop } from "react-icons/di";

import 'animate.css';

import "./project-card.scss";
import Button from "../button/Button";

function ProjectCard(props) {
	const { project } = props;
	const [description, setDescription] = useState("");

	useEffect(() => {
		setDescription(project.descr_short);
	}, [project]);

	const mouseEnter = () => {
		setDescription(project.descr_full);
	}

	const mouseLeave = () => {
		setDescription(project.descr_short);
	}
	// console.log(props);
	return (
		<div className='entire-card animate__animated animate__fadeInUp animate__fast' onMouseEnter={() => mouseEnter()} onMouseLeave ={() => mouseLeave()}>
			<div
				className='cover-side'
				style={{ backgroundImage: `url(${project.cover})` }}
			></div>
			<InfoCard project={project} description={ description} />
		</div>
	);
}

const InfoCard = (props) => {
	const { project, description } = props;


	

	// const projectCardEnter = () => {
	// 	console.log(project.descr_full);
	// 	description = "lallalal";
	// }
	

	return (
		<div className='project-card'>
			<div className='project-card-left'>
				<div className='project-card-top'>{project.type}</div>
				<h2>{project.title}</h2>
				<p>{description}</p>
				<div className='project-card-tech-list'>
					<>
						{project.technologies.map((tech, i) => {
							return (
								<div className='project-card-tech-item' key={i}>
									{tech.icon}
									<p>{tech.techName}</p>
								</div>
							);
						})}
					</>
				</div>
				<div className='project-buttons'>
					{project.links.map((el, i) => {
						return (
							<Button
								className='project-button'
								key={i}
								onClick={() => window.open(el.link, "_blank")}
							>
								{el.name}{" "}
							</Button>
						);
					})}					
				</div>
			</div>
			<div className='project-card-right'>
				<img src={project.pic} alt='' />
			</div>
		</div>
	);
};

export default ProjectCard;
