import React, { useState } from "react";

import Button from "../button/Button";
import { useNavigate } from "react-router";

import "./small-top-nav.scss";

const SmallTopNav = () => {
	const [active, setActive] = useState(["active", "", "", "", ""]);

	let navigate = useNavigate();

	const cliked = (index, path) => {
		const newActiveState = ["", "", ""];
		newActiveState[index] = "active";
		setActive(newActiveState);
		navigate(`/${path}`);
	};
	return (
		<div className='small-nav-wrapper'>
			<span className={`${active[0]}`} onClick={() => cliked(0, "")} href=''>
				<span className={`small-nav-text ${active[0]}`}>Home</span>
				<i className={`bx bx-home small-nav-icon ${active[0]}`}></i>
			</span>
			<span className={`${active[1]}`} onClick={() => cliked(1, "project")}>
				<span className='small-nav-text'>Projects</span>
				<i className={`bx bx-code-alt small-nav-icon ${active[1]}`}></i>
			</span>
			<span className={`${active[2]}`} onClick={() => cliked(2, "education")}>
				<span className='small-nav-text'>Jobs/Education</span>
				<i className={`bx bxs-graduation small-nav-icon ${active[2]}`}></i>
			</span>
			<div className='btn-cv-small-top-nav'>
				<Button
					onClick={() =>
						window.open(
							"https://drive.google.com/file/d/1BVsLQWjmi5hrsoRbjI2vJXee-d52eebe/view",
							"_blank"
						)
					}
				>
					<span>Resume</span>
					<i className='bx bx-download bx-md'></i>
				</Button>
			</div>
		</div>
	);
};

export default SmallTopNav;
