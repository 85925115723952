import { BrowserRouter } from "react-router-dom";
import SideBarLeft from "./components/side-bar-left/SideBarLeft";
import SideBarRight from "./components/side-bar-right/SideBarRight";
import SmallTopNav from "./components/small-top-nav/SmallTopNav";
import RoutesHandler from "./config/RoutesHandler";

import "./App.scss";

function App() {
	return (
		<BrowserRouter>
			<div className="full-page">
				<div className="side-space"></div>
				<div className='page-wrapper'>
					<SideBarLeft />
					<div className='main'>
						<SmallTopNav />
						<RoutesHandler />
					</div>
					<SideBarRight />
				</div>
				<div className="side-space"></div>
			</div>
		</BrowserRouter>
	);
}

export default App;
