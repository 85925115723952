import React from "react";

import SkillsList from "../skills-list/SkillsList";

import { skillsData } from "../../data/skills-data";

import "./about.scss";

const About = () => {

	return (
		<div className='about-wrapper'>
			<div className="about-info">
				<h2>About Me</h2>
				<div className='about-page-text'>
					<p>
						Hi my name is Axan Similien. 
						I am an enthusiastic full-stack software engineer that loves 
						to build software that solves other people's problems. 
						I have a bachelor's in software engineering from Utah Valley University. 
						I have worked for multiple companies where I acquired several skills that 
						will be beneficial for your team like design, and programming, and some soft skills 
						like communication, teamwork, and adaptability. 
						When I am not working I like to play guitar, soccer, and cooking. 
						In the future I see myself shifting my career to Artificial intelligence 
						to solve people's problems more efficiently.
					</p>
				</div>
			</div>
			<div className='what-i-do'>
				<h2>What I can Do</h2>
				<div className='what-i-do-list'>
					<WhatIDoCard
						title='Web Development'
						iconC='bx bx-globe'
						description='I Enjoyed doing web App and Website using various technologies.'
					/>
					<WhatIDoCard
						title='Back-End'
						iconC='bx bx-server'
						description='I can use numerous technology to build Back End and API. For example: NodeJS, Java, C#.'
					/>
					<WhatIDoCard
						title='Front-End'
						iconC='bx bx-laptop'
						description='I use different libraries and framework to design UI. React, Angular, SCSS etc.'
					/>
				</div>
			</div>

			<div className="skills-about">
				<h2>Skills</h2>
				<SkillsList skills={skillsData} />
			</div>
		</div>
	);
};

const WhatIDoCard = ({ title, iconC, description }) => {

	return (
		<div className='what-i-do-card'>
			<i className={iconC}></i>
			<h3>{title}</h3>
			<p>{description}</p>
		</div>
	);

}

export default About;
