import React from "react";
import {
	VerticalTimeline,
	VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { FiBriefcase } from "react-icons/fi";
import { FaGraduationCap } from "react-icons/fa";
import { IoMdBriefcase } from "react-icons/io";

import "./time-line.scss";
import logo1 from "../../assets/timeline/action-trak.jpeg";
import logo2 from "../../assets/timeline/elite-roofing.jpeg";
import logo3 from "../../assets/timeline/consensus.jpeg";
import ensignLogo from "../../assets/timeline/ensign.jpeg";
import uvuLogo from "../../assets/timeline/uvu.jpeg";

function TimeLine() {
	const timeInfoArray = [
		{
			date: "Jan 2022 - present",
			title: "Back End Developer",
			subTitle: "ActionTrak",
			logo: logo1,
			icon: <IoMdBriefcase />,
			descriptions: [
				{
					info: "Designing the Back-End API, so different interfaces like the Mobile and the Desktop Apps can use the server.",
				},

				{
					info: "Designing the Server App Architecture and Database Schemas.",
				},
			],
			background: "rgb(33, 150, 243)",
		},
		{
			date: "May 2018 - May 2022",
			title: "B.S. Software Engineering",
			subTitle: "Utah Valley Uniservity",
			logo: uvuLogo,
			icon: <FaGraduationCap />,
			background: "rgb(245, 0, 87)",
		},
		{
			date: "Nov 2017 - Feb 2019",
			title: "Full-Stack Developer",
			subTitle: "Elite Roofing",
			logo: logo2,
			icon: <IoMdBriefcase />,
			descriptions: [
				{
					info: "Designed and developed the web application UI and front-end using Angular.",
				},

				{
					info: "Created and developed back-end server app using NodeJS and ExpressJS.",
				},
				{
					info: "Created an application that helps managing members and facilitating communication among them.",
				},
			],
			background: "rgb(33, 150, 243)",
		},
		{
			date: "Jun 2016 - Sep 2016",
			title: "QA Programmer (internship)",
			subTitle: "Consensus",
			logo: logo3,
			icon: <IoMdBriefcase />,
			descriptions: [
				{
					info: "Designed more than 15 integrated tests for QA team to use to test the company’s web app.",
				},

				{
					info: "Helped create a library for the testing environment that the company was used.",
				},
			],
			background: "rgb(33, 150, 243)",
		},
		{
			date: "Jul 2015 - Nov 2017",
			title: "Programming Tutor",
			subTitle: "Ensign College",
			logo: ensignLogo,
			icon: <IoMdBriefcase />,
			descriptions: [
				{
					info: "Helped student understand various programming concepts in Java, C#, Python, JavaScript.",
				},

				{
					info: "Assisted students with their homework and various questions they have.",
				},
			],
			background: "rgb(33, 150, 243)",
		},
		{
			date: "Aug. 2017",
			title: "A.S Software Development",
			subTitle: "Ensign College",
			logo: ensignLogo,
			icon: <FaGraduationCap />,
			background: "rgb(245, 0, 87)",
		},
	];

	return (
		<div>
			<VerticalTimeline>
				{timeInfoArray.map(({ date, title, subTitle, logo, descriptions,icon, background }, i) => {
					return (
						<TimeCard
							key={i}
							date={date}
							title={title}
							subTitle={subTitle}
							logo={logo}
							descriptions={descriptions}
							icon={icon}
							background= {background}
						/>
					);
				})}
			</VerticalTimeline>
		</div>
	);
}

const TimeCard = ({ title, date, subTitle, descriptions, logo, icon, background }) => {
	// console.log(descriptions);
	return (
		<VerticalTimelineElement
			className='vertical-timeline-element--work'
			date={date}
			iconStyle={{ background: `${background}`, color: "#fff" }}
			icon={icon}
		>
			<div className='time-card-header'>
				<div>
					<h3 className='vertical-timeline-element-title'>{title}</h3>
					<h4 className='vertical-timeline-element-subtitle'>{subTitle}</h4>
				</div>
				<div className='time-card-header-logo'>
					<img src={logo} alt={ title}/>
				</div>
			</div>

			<div className='time-card-description'>
				<ul>
					{descriptions
						? descriptions.map((item, i) => <li key={i}>{item.info}</li>)
						: null}
				</ul>
			</div>
		</VerticalTimelineElement>
	);
};

export default TimeLine;
