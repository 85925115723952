import React from "react";
import SliderCustom from "../slider/SliderCustom";

import profile from "../../assets/pictures/personal/profile.JPG";

import "./header.scss";
import Button from "../button/Button";

function Header() {
	return (
		<div className="slider-wrapper">
			<SliderCustom>
				<Header1 />
				<h2></h2>
			</SliderCustom>
		</div>
	);
}

const Header1 = () => {
	return (
		<div className='header1'>
			<div className='header1-left-div'>
				<h1>
					I'm Axan Similien <br />
					<span>Software </span>
					Engineer
				</h1>
				<div className='header1-left-div-sub-header'>
					<p>I love to build Software that makes a difference in people's lives.</p>
				</div>
				<div className='header1-buttons-group'>
					<div className="header1-left-div-buttons">
						<Button >
							<span>Hire me</span>
							<i className='bx bx-right-arrow-alt'></i>
						</Button>
						<Button>
							<span className="resume">Resumé</span>
							<span className="cv">CV</span>
						</Button>
					</div>
					{/* <div>whatsapp</div> */}
				</div>
			</div>
			<div className='header1-right-div'>
				{/* the profile picture is in the background of the div */}
			</div>
		</div>
	);
};

export default Header;
